<template>
	<b-container>
		<b-row>
			<div class="centered">
				<h1 class="mb-0  mb-3">
					<span>{{ $t("Napísali") }}</span>
					<span class="w-color-primary z-fancy-underline">
						{{ $t("o nás") }}
						<svg-fancy-underline-icon />
					</span>
				</h1>
			</div>
			<div class="box-container">
				<div v-for="(item, index) in svgArr" :key="index" class="box">
					<img :src="item.logo" />
				</div>
			</div>
		</b-row>
	</b-container>
</template>

<script>
export default {
	components: {
		"svg-fancy-underline-icon": () =>
			import("@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline")
	},
	data() {
		return {
			svgArr: [
				{
					logo: require("../_assets/icons/media/forbes_ptxlkr 1.svg")
				},
				{
					logo: require("../_assets/icons/media/markiza_verzia-1 1.svg")
				},
				{
					logo: require("../_assets/icons/media/tv-joj1.svg")
				},
				{
					logo: require("../_assets/icons/media/ta31.svg")
				},
				{
					logo: require("../_assets/icons/media/rtvs1.svg")
				},
				{
					logo: require("../_assets/icons/media/radio-expres 1.svg")
				},
				{
					logo: require("../_assets/icons/media/fun-radio 1.svg")
				},
				{
					logo: require("../_assets/icons/media/fici_qzj9hu 1.svg")
				},
				{
					logo: require("../_assets/icons/media/startitup_logo_yykuce1.svg")
				},
				{
					logo: require("../_assets/icons/media/sme_ugv0j31.svg")
				},
				{
					logo: require("../_assets/icons/media/refresher_vdxibe1.svg")
				},
				{
					logo: require("../_assets/icons/media/dobre-noviny 1.svg")
				},
				{
					logo: require("../_assets/icons/media/tyzden1.svg")
				},
				{
					logo: require("../_assets/icons/media/korzar 1.svg")
				},
				{
					logo: require("../_assets/icons/media/trend1.svg")
				},
				{
					logo: require("../_assets/icons/media/dobra-skola_jurdru 1.svg")
				},
				{
					logo: require("../_assets/icons/media/zones_sk_logo_k7iscc1.svg")
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.centered {
	z-index: 1000;
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 2rem;
}

.box-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.box {
	height: 7rem;
	width: 220px;
	display: flex;
	justify-content: center;
	img {
		place-self: center center;
		width: auto;
		height: auto;
		margin:1rem;
	}
}
</style>
